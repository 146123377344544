<template>
    <div class="jointly-mechanism-page">
        <CompModal class="jointly-mechanism-modal" ref="comp_modal" title="关联机构" isFull :onSuccess="onSubmit">
            <template #head-operate>
                <div class="modal-operate-btn" @click="onDisplayAddOrg">新增机构</div>
            </template>
            <comp-table ref="comp_table" :columns="columns" :isToolbar="false" :table-api="tableApi">
                <template v-slot:search="data">
                    <FormItem :label-width="100" label="机构名称" style="margin-bottom: 0">
                        <Input v-model.trim="data.search.orgName" placeholder="请输入机构名称" style="width: 160px"></Input>
                    </FormItem>
                </template>
            </comp-table>
            <div class="customize-org" v-if="orgObj">
                <p class="name">已选机构：{{ orgObj.orgName }}</p>
                <p class="btns-box">
                    <Button class="btn" @click="orgObj = null">选择已有机构</Button>
                    <Button class="btn" @click="onDisplayAddOrg">新增其他机构</Button>
                </p>
            </div>
        </CompModal>

        <MechanismFormStep ref="mechanism_form_step" @on-submit="onChangeCustomizeMechanism"></MechanismFormStep>
    </div>
</template>

<script>
import CompTable from "../../components/comp-table.vue"
import LiefengModal from "@/components/LiefengModal"
import MechanismFormStep from "./mechanism_form_step.vue"
import CompModal from "../../../residentdatabase/components/CompModal.vue"
import Request from "../../utils/request"

export default {
    components: { CompTable, LiefengModal, CompModal, MechanismFormStep },

    data() {
        return {
            // 表格 API 地址
            tableApi: `/gateway/apps/org/org/pc/organization/selectOrganizationPage?custGlobalId=${this.$core.getUserInfo().custGlobalId}&orgCode=${this.$core.getUserInfo().orgCode}&orgType=1&oemCode=${this.$core.getUserInfo().oemCode}&parentId=0`,

            display: false,

            selectRadio: {},

            columns: [
                {
                    title: "单选框",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("Radio", {
                            style: {
                                "margin-right": 0,
                            },
                            props: {
                                value: this.selectRadio.orgId === params.row.orgId,
                            },
                            on: {
                                "on-change": _ => {
                                    this.selectRadio = params.row
                                },
                            },
                        })
                    },
                },
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "机构组织编码",
                    key: "orgCode",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "所属组织纬度",
                    key: "dimensionName",
                    minWidth: 200,
                    align: "center",
                },
            ],

            // 已选机构
            orgObj: null,
        }
    },

    methods: {
        open(id, orgCode) {
            this.companyId = id

            if (orgCode) {
                this.getOrgCodeInfo(orgCode)
            }

            this.$refs.comp_modal.display()
        },

        getOrgCodeInfo(orgCode) {
            Request.get("/gateway/org/pc/organization/selectOrgByOrgCode", {
                orgCode: orgCode,
            }).then(res => {
                this.orgObj = {
                    orgName: res.orgName,
                    orgCode: res.orgCode,
                }
                this.selectRadio = res
            })
        },

        onChangeModal(flag) {
            // 改变弹窗显示状态
            this.display = flag
        },

        /**
         * 新增机构
         */
        onDisplayAddOrg() {
            this.$refs.mechanism_form_step.display()
        },

        onChangeCustomizeMechanism(evt) {
            this.onSubmit(evt.value)
        },

        onSubmit(data) {
            if (!data && !this.selectRadio) {
                return this.$Message.success({
                    content: "请选择或创建机构",
                    background: true,
                })
            }

            const fd = {
                companyId: this.companyId,
                oemCode: this.$core.getUserInfo().oemCode,
            }

            if (data) {
                Object.assign(fd, data)
            } else {
                fd.orgCode = this.selectRadio.orgCode
            }

            this.$post(`/gateway/api/sycompany/pc/company/associationOrg`, fd, {
                "Context-Type": "application/json",
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "关联成功",
                        background: true,
                    })
                    this.$refs.comp_modal.close()
                    this.$emit("on-change", {
                        tag: "mechanism",
                        value: {
                            companyId: this.companyId,
                            orgCode: sr.orgCode,
                        },
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>

<style lang="less">
.modal-operate-btn {
    cursor: pointer;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2faaf7;
    color: #fff;
    border-radius: 4px;
    height: 32px;
}

.jointly-mechanism-modal {
    .customize-org {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.95);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999;

        .name {
            font-size: 16px;
            color: #333;
            margin-bottom: 10px;
        }

        .btns-box {
            display: flex;
            align-items: center;

            .btn {
                margin: 0 10px;
            }
        }
    }
}
</style>
